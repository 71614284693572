<template>
  <div class="container">
    <LoadingGif class="loading-gif" v-if="loading"></LoadingGif>

    <!-- Table -->
    <div v-if="!loading">
      <div v-if="!case_file.id">
        <CCardHeader>
          <h2>File Not Found!</h2>
        </CCardHeader>
      </div>

      <CRow v-if="case_file.id">
        <CRow class="p-3 mr-3">
          <h2>Client: {{ case_file.client_name }}</h2>
        </CRow>

        <!-- Action Buttons -->
        <CRow>
          <CButton
            color="success"
            @click="editModal = true"
            class="mb-1 mt-1 ml-3"
          >
            Edit Case Details
          </CButton>
          <CButton
            color="success"
            @click="bringupDatesModal = true"
            class="mb-1 mt-1 ml-3"
          >
            Change BU Details
          </CButton>
          <CButton
            color="dark"
            @click="statusLogModal = true"
            class="mb-1 mt-1 ml-3"
          >
            Status Log
          </CButton>
          <CButton
            color="info"
            @click="actionLogModal = true"
            class="mb-1 mt-1 ml-3"
          >
            Action Log
          </CButton>
          <CButton
            color="primary"
            @click="billingModal = true"
            class="mb-1 mt-1 ml-3"
          >
            Billing
          </CButton>

          <CButton
            color="danger"
            v-if="case_file.deleted_at === null"
            @click="deleteModal = true"
            class="mb-1 mt-1 ml-3"
          >
            Delete
          </CButton>
          <CButton
            v-if="case_file.deleted_at !== null"
            color="warning"
            @click="restoreModal = true"
            class="mb-1 mt-1 ml-3"
          >
            Restore
          </CButton>

          <CDropdown
            class="m-2 mt-3"
            toggler-text="File Actions"
            color="secondary"
          >
            <CDropdownItem
              v-if="
                case_file.dormant_at ||
                case_file.closed_at ||
                case_file.sendtoclient_at
              "
              @click="move_to_active"
              >Move to Active
            </CDropdownItem>
            <CDropdownItem
              v-if="case_file.closed_at === null"
              @click="move_to_closed"
              >Move to Closed</CDropdownItem
            >
            <CDropdownItem
              v-if="case_file.dormant_at === null"
              @click="move_to_dormant"
              >Move to Dormant
            </CDropdownItem>

            <CDropdownItem
              v-if="case_file.sendtoclient_at === null"
              @click="move_to_sendtoclient"
              >Move to Send To Client</CDropdownItem
            >
          </CDropdown>
        </CRow>

        <div class="container">
          <table class="mt-2 table table-bordered table-striped">
            <tr>
              <th style="width: 160px !important">File Reference</th>
              <td>{{ case_file.file_reference }}</td>
            </tr>
            <tr>
              <td style="width: 160px !important"><strong>Folder</strong></td>
              <!-- <td>{{ case_file.file_reference }}</td> -->
              <td>
                <CBadge
                  v-if="case_file.deleted_at !== null"
                  class="m-1 role_pill"
                  shape="pill"
                  color="danger"
                  >Deleted</CBadge
                >
                <CBadge
                  v-if="case_file.closed_at !== null"
                  class="m-1 role_pill"
                  shape="pill"
                  color="dark"
                  >Closed</CBadge
                >
                <CBadge
                  v-if="case_file.dormant_at !== null"
                  class="m-1 role_pill"
                  shape="pill"
                  color="info"
                  >Dormant</CBadge
                >
                <CBadge
                  v-if="case_file.sendtoclient_at !== null"
                  class="m-1 role_pill"
                  shape="pill"
                  color="warning"
                  >Send To Client</CBadge
                >
                <CBadge
                  v-if="
                    case_file.deleted_at === null &&
                    case_file.dormant_at === null &&
                    case_file.sendtoclient_at === null &&
                    case_file.closed_at === null
                  "
                  class="m-1 role_pill"
                  shape="pill"
                  color="success"
                  >Active</CBadge
                >
              </td>
            </tr>
            <tr>
              <td><strong>Date Opened</strong></td>
              <td>{{ case_file.date_opened }}</td>
            </tr>
            <tr>
              <td><strong>Category</strong></td>
              <td>{{ case_file.category }}</td>
            </tr>
            <tr>
              <td><strong>Matter Summary</strong></td>
              <td>{{ case_file.matter_summary }}</td>
            </tr>
            <tr>
              <td><strong>Primary Lawyer</strong></td>
              <td>{{ case_file.pri_lawyer }}</td>
            </tr>
            <tr>
              <td><strong>Primary BringUpDate</strong></td>
              <td>{{ case_file.bring_up_date_pri }}</td>
            </tr>
            <tr>
              <td><strong>Secondary Lawyer</strong></td>
              <td>{{ case_file.sec_lawyer }}</td>
            </tr>
            <tr>
              <td><strong>Secondary BringUpDate</strong></td>
              <td>{{ case_file.bring_up_date_sec }}</td>
            </tr>
          </table>
        </div>
      </CRow>
    </div>

    <!-- Edit -->
    <CModal
      :title="`Edit '${case_file.client_name}'`"
      color="success"
      :show.sync="editModal"
    >
      <CRow>
        <CCol md="12">
          <CCard>
            <CCardBody>
              <CInput
                description=""
                :value="case_file.client_name"
                label="Client Name"
                horizontal
                autocomplete="name"
                v-model="edit_form.client_name"
              />
              <CInput
                description=""
                label="File Reference"
                horizontal
                autocomplete="ref"
                v-model="edit_form.reference"
              />
              <CInput
                v-model="edit_form.date"
                label="Date Opened"
                type="date"
                horizontal
                :value="edit_form.date"
              />

              <CRow class="mb-3">
                <label class="col-sm-4">Category</label>
                <select
                  class="col-sm-7 form-control"
                  placeholder="Please select"
                  v-model="edit_form.category"
                >
                  <option v-for="c in categories" :key="c">
                    {{ c }}
                  </option>
                </select>
              </CRow>

              <CTextarea
                label="Matter Summary"
                placeholder="Summary"
                horizontal
                rows="6"
                v-model="edit_form.summary"
              />
            </CCardBody>
          </CCard>
          <CButton @click="sumbit_edit_case(case_file)" color="success" block>
            Save
          </CButton>
        </CCol>
      </CRow>
    </CModal>

    <!-- Change BU Details -->
    <CModal
      title="Change BU Details"
      color="success"
      :show.sync="bringupDatesModal"
      :centered="true"
    >
      <CRow>
        <CCol md="12">
          <CCard>
            <CCardBody>
              <label>Primary User</label>
              <select
                class="form-control mb-2"
                placeholder="Please select"
                v-model="bu_dates_form.pri_user"
              >
                <option v-for="l in lawyers" :key="l">{{ l }}</option>
              </select>

              <CInput
                label="Primary User Bring-up Date"
                type="date"
                horizontal
                v-model="bu_dates_form.pri_user_bu_date"
              />
              <label>Secondary User</label>
              <select
                class="form-control mb-2"
                placeholder="Please select"
                v-model="bu_dates_form.sec_user"
              >
                <option v-for="l in lawyers" :key="l">{{ l }}</option>
              </select>

              <CInput
                label="Secondary User Bring-up Date"
                type="date"
                horizontal
                v-model="bu_dates_form.sec_user_bu_date"
              />
            </CCardBody>
          </CCard>
          <CButton @click="submit_edit_budates" color="success" block>
            Save
          </CButton>
        </CCol>
      </CRow>
    </CModal>

    <!-- Status Log -->
    <CModal
      title="Status Log"
      :show.sync="statusLogModal"
      size="xl"
      :centered="true"
      color="dark"
    >
      <div id="status_wrapper" class="dataTables_wrapper no-footer">
        <div class="dt-buttons mb-3">
          <CButton
            @click="
              download_excel('status', case_statuses, case_file.client_name)
            "
            color="warning"
            class="ml-3"
          >
            Excel
            <span
              class=""
              v-if="
                downloading &&
                download_type == 'excel' &&
                download_section == 'status'
              "
            >
              <img class="gif" src="/img/loading-sm.gif" />
            </span>
          </CButton>
          <CButton
            @click="
              download_pdf('status', case_statuses, case_file.client_name)
            "
            color="warning"
            class="ml-3"
          >
            PDF
            <span
              class=""
              v-if="
                downloading &&
                download_type == 'pdf' &&
                download_section == 'status'
              "
            >
              <img class="gif" src="/img/loading-sm.gif" />
            </span>
          </CButton>
          <CButton color="dark" @click="newStatusItemModal = true" class="ml-3">
            Add to Status Log
          </CButton>

          <!-- <span id="status_filter" class="ml-3 dataTables_filter">
            <label
              >Search:<input
                type="search"
                class=""
                placeholder=""
                aria-controls="status"
                v-model="search_status_log"
            /></label>
          </span> -->
        </div>

        <table
          class="
            table table-bordered table-striped
            dataTable
            no-footer
            dtr-inline
          "
          id="status"
          aria-describedby="status_info"
        >
          <thead>
            <tr role="row">
              <th aria-controls="status" aria-label="No: ">No</th>
              <th aria-controls="status" aria-label="Date: ">Date</th>
              <th aria-controls="status" aria-label="User: ">User</th>
              <th aria-controls="status" aria-label="Status: ">Status</th>
              <th aria-controls="status" aria-label="Edit: ">Edit</th>
              <th aria-controls="status" aria-label="Delete: ">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(c, i) in case_statuses" :key="i">
              <td class="dtr-control sorting_1">{{ i + 1 }}</td>
              <td>{{ c.date_performed }}</td>
              <td>{{ c.user_initials }}</td>
              <td>
                {{ c.status }}
              </td>
              <td>
                <CButton @click="editStatusItem(c)" color="success"
                  >Edit</CButton
                >
              </td>
              <td>
                <CButton @click="deleteStatusItem(c)" color="danger"
                  >Delete</CButton
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CModal>

    <!-- Add Status Log Item -->
    <CModal
      title="Add Status Log Item"
      color="dark"
      :show.sync="newStatusItemModal"
      :centered="true"
    >
      <div>
        <div class="form-group">
          <input name="case_id" type="hidden" value="634" />
        </div>
        <CRow class="mb-3 form-group">
          <label class="col-sm-4">User</label>
          <select
            class="col-sm-7 mb-2 form-control"
            v-model="status_log_form.user"
            :value="user.initials"
            placeholder="Please select"
          >
            <option v-for="l in lawyers" :key="l">{{ l }}</option>
          </select>
        </CRow>
        <div class="form-group">
          <CInput
            label="Date Performed"
            type="date"
            horizontal
            v-model="status_log_form.date"
          />
        </div>
        <div class="form-group">
          <CTextarea
            label="Status"
            placeholder="Status description goes here"
            horizontal
            rows="9"
            v-model="status_log_form.status"
          />
        </div>

        <CButton @click="submit_status_log(user, case_file)" block color="dark"
          >Submit</CButton
        >
      </div>
    </CModal>

    <!--Edit Status Item Modal -->
    <CModal
      title="Edit Status Item"
      :show.sync="editStatusItemModal"
      :centered="true"
      color="dark"
    >
      <div id="status_wrapper" class="dataTables_wrapper no-footer">
        <div>
          <div class="form-group">
            <label for="User">User</label>
            <br />
            <select
              name="user"
              id="user"
              class="
                form-control form-select
                block
                rounded-md
                shadow-sm
                mt-1
                w-full
              "
              v-model="editStatusItemForm.user"
            >
              <option v-for="l in lawyers" :key="l">{{ l }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="DatePerfomed">Date Performed</label>
            <input
              class="form-control"
              name="DatePerfomed"
              type="date"
              id="DatePerfomed"
              v-model="editStatusItemForm.date"
            />
          </div>
          <div class="form-group">
            <label for="Status">Status</label>
            <textarea
              class="form-control"
              placeholder="Status"
              name="Status"
              cols="50"
              rows="10"
              id="Status"
              v-model="editStatusItemForm.status"
            ></textarea>
          </div>

          <CButton block color="dark" @click="editStatusItemFormSubmit">
            Save
          </CButton>
        </div>
      </div>
    </CModal>

    <!--Delete Status Item Modal -->
    <CModal
      title="Edit Status Item"
      :show.sync="deleteStatusItemModal"
      :centered="true"
      color="dark"
    >
      <div id="status_wrapper" class="dataTables_wrapper no-footer">
        <p>Delete</p>
      </div>
    </CModal>

    <!-- Action Log -->
    <CModal
      title="Action Log"
      color="info"
      :show.sync="actionLogModal"
      :centered="true"
      size="xl"
    >
      <div id="actionlogtable_wrapper" class="dataTables_wrapper no-footer">
        <div class="dt-buttons mb-3">
          <CButton
            @click="
              download_excel('actions', case_actions, case_file.client_name)
            "
            color="warning"
            class="ml-3"
          >
            Excel
            <span
              class=""
              v-if="
                downloading &&
                download_type == 'excel' &&
                download_section == 'actions'
              "
            >
              <img class="gif" src="/img/loading-sm.gif" />
            </span>
          </CButton>
          <CButton
            @click="
              download_pdf('actions', case_actions, case_file.client_name)
            "
            color="warning"
            class="ml-3"
          >
            PDF
            <span
              class=""
              v-if="
                downloading &&
                download_type == 'pdf' &&
                download_section == 'actions'
              "
            >
              <img class="gif" src="/img/loading-sm.gif" />
            </span>
          </CButton>
          <CButton color="info" @click="newLogItemModal = true" class="ml-3">
            Add Log Item
          </CButton>
          <!-- <span id="actionlogtable_filter" class="ml-3 dataTables_filter">
            <label
              >Search:<input
                type="search"
                v-model="search_action_log"
                class=""
                placeholder=""
                aria-controls="actionlogtable"
            /></label>
          </span> -->
        </div>

        <table
          class="
            table table-bordered table-striped
            dataTable
            no-footer
            dtr-inline
          "
          id="actionlogtable"
          aria-describedby="actionlogtable_info"
        >
          <thead>
            <tr role="row">
              <th aria-controls="actionlogtable" aria-label="No: ">No</th>
              <th aria-controls="actionlogtable" aria-label="Date: ">Date</th>
              <th aria-controls="actionlogtable" aria-label="User: ">User</th>
              <th aria-controls="actionlogtable" aria-label="Action: ">
                Action
              </th>
              <th
                aria-controls="actionlogtable"
                aria-label="Action Particulars: "
              >
                Action Particulars
              </th>
              <th aria-controls="actionlogtable" aria-label="Duration: ">
                Duration (Hrs)
              </th>
              <th aria-controls="actionlogtable" aria-label="Edit: ">Edit</th>
              <th aria-controls="actionlogtable" aria-label="Delete: ">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(c, i) in case_actions" :key="i">
              <td class="dtr-control sorting_1">{{ i + 1 }}</td>
              <td>{{ c.date_performed }}</td>
              <td>{{ c.user_initials }}</td>
              <td>{{ c.action }}</td>
              <td>{{ c.action_details }}</td>
              <td>{{ c.duration }}</td>

              <td>
                <CButton
                  color="primary"
                  @click="billActionLogItem(c)"
                  class="ml-3"
                  v-if="!billed_actions.includes(c.id)"
                >
                  Bill
                </CButton>
                <CButton disabled color="secondary" class="ml-3" v-else>
                  Billed
                </CButton>
                <CButton
                  color="success"
                  @click="editActionLogItem(c)"
                  class="ml-3"
                >
                  Edit
                </CButton>
              </td>
              <td>
                <CButton
                  color="danger"
                  @click="deleteActionLogItem(c)"
                  class="ml-3"
                >
                  Delete
                </CButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CModal>

    <!-- New Action Log Item -->
    <CModal
      title="New Log Item"
      color="info"
      :show.sync="newLogItemModal"
      :centered="true"
    >
      <div>
        <div class="form-group">
          <div class="form-group">
            <input name="case_id" type="hidden" value="119" />
            <input
              name="billed_by"
              type="hidden"
              v-model="action_log_form.billed_by"
            />
          </div>

          <CInput
            label="Date Perfomed"
            type="date"
            horizontal
            v-model="action_log_form.date"
          />
        </div>
        <CRow class="mb-3 form-group">
          <label class="col-sm-3">User</label>
          <select
            class="col-sm-8 mb-2 form-control"
            v-model="action_log_form.user_initials"
            placeholder="Please select"
          >
            <option v-for="(l, i) in lawyers" :key="i">{{ l }}</option>
          </select>
        </CRow>
        <CRow class="mb-3 form-group">
          <label class="col-sm-3">Action</label>
          <select
            class="col-sm-8 mb-2 form-control"
            v-model="action_log_form.action"
            placeholder="Please select"
          >
            <option v-for="a in actions" :key="a">{{ a }}</option>
          </select>
        </CRow>
        <div class="form-group">
          <CRow>
            <CCol sm="3">
              <label>Duration</label>
            </CCol>
            <!-- <CCol sm="3">
              <label>Hours</label>
              <input
                class="form-control"
                v-model="action_log_form.hrs"
                
                placeholder="Hours"
                type="number" min="0"
                min="0"
              />
            </CCol>
            <CCol sm="3">
              <CInput
                v-model="action_log_form.mins"
                
                label="Minutes"
                placeholder="Minutes"
                type="number" min="0"
                min="0"
                max="59"
              />
            </CCol> -->
            <CCol sm="3">
              <CInput
                label="Decimal Hours"
                placeholder=""
                type="number"
                min="0"
                v-model="action_log_form.duration"
              />
            </CCol>
          </CRow>
        </div>
        <div class="form-group">
          <CTextarea
            label="Action Particulars"
            placeholder="Action particulars goes here"
            horizontal
            rows="9"
            v-model="action_log_form.details"
          />
        </div>

        <CButton @click="submit_action_log(user, case_file)" block color="info"
          >Submit</CButton
        >
      </div>
    </CModal>

    <!--Edit Action Log Item Modal -->
    <CModal
      title="Edit Action Log Item"
      :show.sync="editActionLogItemModal"
      :centered="true"
      color="info"
    >
      <div id="status_wrapper" class="dataTables_wrapper no-footer">
        <div>
          <div class="form-group">
            <label for="User">User</label>
            <br />
            <select
              name="user"
              id="user"
              class="form-control block rounded-md shadow-sm mt-1 w-full"
              v-model="editActionItemForm.user"
            >
              <option v-for="l in lawyers" :key="l">{{ l }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="DatePerfomed">Date</label>
            <input
              class="form-control"
              name="DatePerfomed"
              type="date"
              id="DatePerfomed"
              v-model="editActionItemForm.date"
            />
          </div>
          <div class="form-group">
            <label for="User">Action</label>
            <br />
            <select
              name="user"
              id="user"
              class="form-control block rounded-md shadow-sm mt-1 w-full"
              v-model="editActionItemForm.action"
            >
              <option v-for="a in actions" :key="a">{{ a }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="Status">Action Particulars</label>
            <textarea
              class="form-control"
              placeholder="Status"
              name="Status"
              cols="30"
              rows="5"
              id="Status"
              v-model="editActionItemForm.details"
            ></textarea>
          </div>

          <div class="form-group">
            <CRow>
              <CCol sm="3">
                <label>Duration</label>
              </CCol>
              <!-- <CCol sm="3">
                <label>Hours</label>
                <input
                  class="form-control"
                  v-model="decimal_to_ordinary(editActionItemForm.duration)[0]"
                  @change="
                    ordinary_to_decimal(
                      editActionItemForm.hrs,
                      editActionItemForm.mins
                    )
                  "
                  @keyup="
                    ordinary_to_decimal(
                      editActionItemForm.hrs,
                      editActionItemForm.mins
                    )
                  "
                  placeholder="Hours"
                  type="number" min="0"
                  min="0"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="decimal_to_ordinary(editActionItemForm.duration)[1]"
                  @change="
                    ordinary_to_decimal(
                      editActionItemForm.hrs,
                      editActionItemForm.mins
                    )
                  "
                  @keyup="
                    ordinary_to_decimal(
                      editActionItemForm.hrs,
                      editActionItemForm.mins
                    )
                  "
                  label="Minutes"
                  placeholder="Minutes"
                  type="number" min="0"
                  min="0"
                  max="59"
                />
              </CCol> -->
              <CCol sm="3">
                <CInput
                  label="Decimal Hours"
                  placeholder=""
                  type="number"
                  min="0"
                  v-model="editActionItemForm.duration"
                />
              </CCol>
            </CRow>
          </div>

          <CButton color="info" block @click="editActionLogItemFormSubmit">
            Save
          </CButton>
        </div>
      </div>
    </CModal>

    <!--Delete Action Log Item Modal -->
    <CModal
      title="Delete Action Log Item"
      :show.sync="deleteActionLogItemModal"
      :centered="true"
      color="dark"
    >
      <div id="status_wrapper" class="dataTables_wrapper no-footer">
        <p>Delete</p>
      </div>
    </CModal>

    <!-- Billing -->
    <CModal
      title="Billing"
      color="primary"
      :show.sync="billingModal"
      :centered="true"
      size="xl"
    >
      <div class="container">
        <div id="billing_wrapper" class="dataTables_wrapper no-footer">
          <div class="dt-buttons mb-3">
            <CButton
              @click="
                download_excel('billings', case_payments, case_file.client_name)
              "
              color="warning"
              class="ml-3"
            >
              Excel
              <span
                class=""
                v-if="
                  downloading &&
                  download_type == 'excel' &&
                  download_section == 'billings'
                "
              >
                <img class="gif" src="/img/loading-sm.gif" />
              </span>
            </CButton>
            <CButton
              @click="
                download_pdf('billings', case_payments, case_file.client_name)
              "
              color="warning"
              class="ml-3"
            >
              PDF
              <span
                class=""
                v-if="
                  downloading &&
                  download_type == 'pdf' &&
                  download_section == 'billings'
                "
              >
                <img class="gif" src="/img/loading-sm.gif" />
              </span>
              <!-- <LoadingGif v-if="downloading" :size="'sm'" /> -->
            </CButton>
            <!-- <LoadingGif class="loading-gif" v-if="loading"></LoadingGif> -->

            <CButton
              color="primary"
              @click="newPaymentModal = true"
              class="ml-3"
            >
              Add Payment
            </CButton>
            <!-- <CButton color="primary" @click="paymentsModal = true" class="ml-3">
              View Payments
            </CButton> -->
            <!-- <span id="billing_filter" class="ml-3 dataTables_filter">
              <label
                >Search:<input
                  type="search"
                  class=""
                  placeholder=""
                  aria-controls="billing"
              /></label>
            </span> -->

            <span class="ml-2"
              >Total Billed: <strong>{{ billed_total }}</strong></span
            >
            <span class="ml-2"
              >Total Paid: <strong>{{ paid_total }}</strong></span
            >
            <span class="ml-2"
              >Total Outstanding:
              <strong>{{ billed_total - paid_total }}</strong></span
            >
          </div>

          <table
            class="
              table table-bordered table-striped
              dataTable
              no-footer
              dtr-inline
            "
            id="billing"
            aria-describedby="billing_info"
          >
            <thead>
              <tr role="row">
                <th aria-controls="billing" aria-label="No: ">No</th>
                <th aria-controls="billing" aria-label="Date: ">Date</th>
                <th aria-controls="billing" aria-label="User: ">User</th>
                <th aria-controls="billing" aria-label="Action: ">Action</th>
                <th aria-controls="billing" aria-label="Billed By: ">
                  Billed By
                </th>
                <th aria-controls="billing" aria-label="Billed: ">Billed</th>

                <th aria-controls="billing" aria-label="Paid: ">Paid</th>
                <th aria-controls="billing" aria-label="Outstanding: ">
                  Outstanding
                </th>
                <th aria-controls="actionlogtable" aria-label="Edit: ">Edit</th>
                <th aria-controls="actionlogtable" aria-label="Delete: ">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(c, i) in case_payments"
                :key="i"
                :class="c.action === 'Payment' ? '' : ''"
              >
                <td class="dtr-control sorting_1">{{ i + 1 }}</td>
                <td>{{ c.billing_date }}</td>
                <td>{{ c.user_initials }}</td>
                <td>{{ c.action }}</td>
                <td>{{ c.billed_by }}</td>
                <td>{{ c.amount_billed }}</td>
                <!-- <td>{{ c.amount }}</td> -->
                <td>{{ c.amount_paid }}</td>
                <!-- <td>{{ c.amount_billed - c.amount_paid }}</td> -->
                <td>{{ calculate_subtotal(i) }}</td>

                <td>
                  <CButton
                    v-if="c.action === 'Payment'"
                    color="success"
                    @click="editPaymentItem(c)"
                    class="ml-3"
                  >
                    Edit Payment
                  </CButton>
                  <CButton
                    v-else
                    color="success"
                    @click="editBillingLogItem(c)"
                    class="ml-3"
                  >
                    Edit
                  </CButton>
                </td>
                <td>
                  <CButton
                    color="danger"
                    @click="deleteBillingLogItem(c)"
                    class="ml-3"
                  >
                    Delete
                  </CButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CModal>

    <!-- Add New Payment-->
    <CModal
      title="Add New Payment"
      color="primary"
      :show.sync="newPaymentModal"
      :centered="true"
    >
      <div>
        <div class="form-group row">
          <div class="col-3">
            <label class="col-sm-4">User</label>
          </div>
          <div class="col-8">
            <select
              class="col-sm-7 form-control"
              placeholder="Please select"
              v-model="payment_form.user_initials"
            >
              <option
                v-for="l in lawyers"
                :key="l"
                :selected="user.initials == l"
              >
                {{ l }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <CInput
            label="Date"
            type="date"
            horizontal
            v-model="payment_form.date"
          />
        </div>
        <CRow class="mb-3">
          <label class="col-sm-3">Action</label>
          <select
            class="col-sm-8 form-control"
            placeholder="Please select"
            v-model="payment_form.action"
          >
            <option value="Payment" selected>Payment</option>
          </select>
        </CRow>

        <!-- <div class="form-group">
          <label>Amount Billed</label>
          <input
            class="form-control"
            type="number"
            min="0"
            v-model="payment_form.amnt_billed"
          />
        </div> -->
        <div class="form-group">
          <div class="row">
            <div class="col-3">
              <label>Amount Paid</label>
            </div>
            <div class="col-9">
              <input
                class="form-control"
                type="number"
                min="0"
                v-model="payment_form.amnt_paid"
              />
            </div>
          </div>
        </div>
        <CButton
          @click="submit_new_payment(user, case_file)"
          block
          color="primary"
          >Submit</CButton
        >
      </div>
    </CModal>

    <!-- Edit Billing-->
    <CModal
      title="Edit Billing"
      color="primary"
      :show.sync="editBillingLogItemModal"
      :centered="true"
    >
      <div>
        <CRow class="mb-3">
          <label class="col-sm-4">User</label>
          <select
            class="col-sm-6 form-control"
            placeholder="Please select"
            v-model="editBillingItemForm.user"
          >
            <option v-for="l in lawyers" :key="l">
              {{ l }}
            </option>
          </select>
        </CRow>
        <div>
          <CInput
            label="Date"
            type="date"
            horizontal
            v-model="editBillingItemForm.date"
          />
        </div>
        <CRow class="mb-3">
          <label class="col-sm-4">Action</label>
          <select
            class="col-sm-7 form-control"
            placeholder="Please select"
            v-model="editBillingItemForm.action"
          >
            <option :value="a.action" v-for="a in case_actions" :key="a.id">
              {{ a.action }} ({{ a.duration }}hrs)
            </option>
          </select>
        </CRow>

        <CRow class="mb-3 form-group">
          <label class="col-sm-4">Amount Billed</label>
          <input
            class="col-sm-7 form-control"
            type="number"
            min="0"
            v-model="editBillingItemForm.amnt_billed"
          />
        </CRow>
        <!-- <CRow class="mb-3 form-group">
          <label class="col-sm-4">Amount Paid</label>
          <input
            class="col-sm-7 form-control"
            type="number"
            min="0"
            v-model="editBillingItemForm.amnt_paid"
          />
        </CRow> -->
        <CButton
          @click="editBillingLogItemFormSubmit(user, case_file)"
          block
          color="primary"
          >Submit</CButton
        >
      </div>
    </CModal>

    <!-- Edit Payment-->
    <CModal
      title="Edit Payment"
      color="primary"
      :show.sync="editPaymentItemModal"
      :centered="true"
    >
      <div>
        <div class="form-group row">
          <div class="col-3">
            <label class="col-sm-4">User</label>
          </div>
          <div class="col-8">
            <select
              class="col-sm-7 form-control"
              placeholder="Please select"
              v-model="editPaymentItemForm.user_initials"
            >
              <option
                v-for="l in lawyers"
                :key="l"
                :selected="user.initials == l"
              >
                {{ l }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <CInput
            label="Date"
            type="date"
            horizontal
            v-model="editPaymentItemForm.date"
          />
        </div>
        <CRow class="mb-3">
          <label class="col-sm-3">Action</label>
          <select
            class="col-sm-8 form-control"
            placeholder="Please select"
            v-model="editPaymentItemForm.action"
          >
            <option value="Payment" selected>Payment</option>
          </select>
        </CRow>

        <!-- <div class="form-group">
          <label>Amount Billed</label>
          <input
            class="form-control"
            type="number"
            min="0"
            v-model="payment_form.amnt_billed"
          />
        </div> -->
        <div class="form-group">
          <div class="row">
            <div class="col-3">
              <label>Amount Paid</label>
            </div>
            <div class="col-9">
              <input
                class="form-control"
                type="number"
                min="0"
                v-model="editPaymentItemForm.amnt_paid"
              />
            </div>
          </div>
        </div>
        <CButton
          @click="editPaymentItemFormSubmit(user, case_file)"
          block
          color="primary"
          >Submit</CButton
        >
      </div>
    </CModal>

    <!-- Payments Log -->
    <CModal
      title="Payments Log"
      color="primary"
      :show.sync="paymentsModal"
      :centered="true"
    >
      Payments Log
    </CModal>

    <!-- Confirm Delete -->
    <CModal
      title="Confirm Delete"
      color="danger"
      :show.sync="deleteModal"
      :centered="true"
    >
      Are you sure you want to delete this case?
      <CRow class="ml-1 mt-3">
        <CButton @click="submit_delete" color="danger">Yes, Delete.</CButton>
      </CRow>
    </CModal>

    <!-- Confirm Restore -->
    <CModal
      title="Confirm Restore"
      color="warning"
      :show.sync="restoreModal"
      :centered="true"
    >
      Are you sure you want to restore this case?
      <CRow class="ml-1 mt-3">
        <CButton @click="submit_restore" color="danger">Yes, Restore.</CButton>
      </CRow>
    </CModal>
  </div>
</template>

<script>
import LoadingGif from "./LoadingGif.vue";

import axios from "axios";
import Swal from "sweetalert2";
import FileSaver from "file-saver";

export default {
  name: "Case",
  components: { LoadingGif },
  data() {
    return {
      loading: false,
      downloading: false,
      download_section: "",
      download_type: "",

      total_paid: 0,
      total_outstanding: 0,

      case_id: "",
      case_file: {},
      case_actions: [],
      case_statuses: [],
      case_payments: [],
      billed_actions: [],

      search_status_log: "",
      search_action_log: "",

      paid_total: "",
      billed_total: "",

      categories: [],
      lawyers: [],
      actions: [],

      user: JSON.parse(localStorage.getItem("user")),

      editModal: false,
      bringupDatesModal: false,
      deleteModal: false,
      restoreModal: false,
      billingModal: false,
      actionLogModal: false,
      paymentsModal: false,
      newPaymentModal: false,
      editBillingLogItemModal: false,
      editPaymentItemModal: false,
      newLogItemModal: false,
      statusLogModal: false,
      newStatusItemModal: false,
      editStatusItemModal: false,
      deleteStatusItemModal: false,
      editActionLogItemModal: false,
      deleteActionLogItemModal: false,

      edit_form: {
        client_name: "",
        reference: "",
        date: "",
        category: "",
        summary: "",
      },

      bu_dates_form: {
        pri_user: "",
        pri_user_bu_date: "",
        sec_user: "",
        sec_user_bu_date: "",
      },

      action_log_form: {
        hrs: 0,
        mins: 0,
        date: this.get_date_today(),
        user_initials: "",
        action: "",
        duration: "",
        details: "",
        billed_by: "",
      },

      status_log_form: {
        date: this.get_date_today(),
        user: "",
        status: "",
      },

      billing_form: {
        user_id: "",
        user_initials: "",
        case_id: "",
        action_id: "",
        action: "Payment",
        date: "",
        amnt_billed: "",
        amnt_paid: "",
        billed_by: "",
        amount: "",
      },

      payment_form: {
        user_id: "",
        user_initials: "",
        case_id: "",
        action_id: "",
        action: "Payment",
        date: this.get_date_today(),
        amnt_billed: "",
        amnt_paid: "",
        billed_by: "",
        amount: "",
      },

      editBillingItemForm: {
        billing_id: "",
        user_id: "",
        user: "",
        case_id: "",
        action: "",
        date: "",
        amnt_billed: "",
        amnt_paid: 0,
        amount: "",
      },

      editPaymentItemForm: {
        billing_id: "",
        user_id: "",
        user_initials: "",
        case_id: "",
        action: "",
        date: "",
        amnt_billed: "",
        amnt_paid: "",
        amount: "",
        user: "",
      },

      editStatusItemForm: {
        status_id: "",
        status: "",
        date: "",
        user: "",
      },

      editActionItemForm: {
        action_id: "",
        date: "",
        user: "",
        action: "",
        details: "",
        hrs: "",
        mins: "",
        duration: "",
      },
    };
  },
  mounted() {
    this.fetch_case_details();
    this.fetch_lawyers();
    this.fetch_categories();
    this.fetch_actions();
    this.calculate_billings();
  },
  computed: {
    filtered_statuses: function () {
      return this.case_statuses.filter((status) => {
        return (
          status.user_initials.match(this.search_status_log) ||
          status.status.match(this.search_status_log)
        );
      });
    },
    filtered_actions: function () {
      return this.case_actions.filter((action) => {
        return (
          action.action.match(this.search_action_log) ||
          action.action_details.match(this.search_action_log) ||
          action.user_initials.match(this.search_action_log)
        );
      });
    },
  },
  methods: {
    increment_subtotal: function (index) {
      if (index < 0) {
        return 0;
      } else {
        let payments = [];

        this.case_payments.forEach((element) => {
          payments.push(element);
        });

        let total_outstanding = 0;
        // console.log(payments);

        const el = payments[index];
        // console.log(el);

        return el.amount_billed + this.increment_subtotal(index - 1);
      }

      // for (let i = 1; i < index + 1; i++) {
      //   const el = payments[index];
      //   // console.log(el);

      //   let outstanding = el.amount_billed;

      //   total_outstanding = total_outstanding + outstanding;
      // }
      // this.total_outstanding = total_outstanding;
      // return total_outstanding;
    },

    calculate_subtotal: function (index) {
      if (index < 0) {
        return 0;
      } else {
        let payments = [];

        this.case_payments.forEach((element) => {
          payments.push(element);
        });

        let total_outstanding = 0;
        // console.log(payments);

        const el = payments[index];
        // console.log(el);

        return (
          el.amount_billed - el.amount_paid + this.calculate_subtotal(index - 1)
        );
      }
    },
    editStatusItem(status) {
      this.editStatusItemModal = true;
      this.editStatusItemForm = {
        date: status.date_performed,
        status: status.status,
        status_id: status.id,
        user: status.user_initials,
      };
      console.log(status);
    },

    editStatusItemFormSubmit() {
      console.log("saving..");
      var this_ = this;
      axios
        .post("/cases/status/log/edit", {
          status_id: this_.editStatusItemForm.status_id,
          status: this_.editStatusItemForm.status,
          user: this_.editStatusItemForm.user,
          date: this_.editStatusItemForm.date,
        })
        .then(function (response) {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Saved!",
            showConfirmButton: false,
            timer: 1500,
          });
          let objIndex = this_.case_statuses.findIndex(
            (status) => status.id === this_.editStatusItemForm.status_id
          );
          this_.case_statuses[objIndex] = {
            date_performed: this_.editStatusItemForm.date,
            status: this_.editStatusItemForm.status,
            user_initials: this_.editStatusItemForm.user,
          };
          console.log(this_.case_statuses);
        })
        .then(function () {
          this_.fetch_case_details();
          this_.editStatusItemModal = false;
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    deleteStatusItem(status) {
      // this.deleteStatusItemModal = true;
      var this_ = this;
      Swal.fire({
        title: "Confirm Delete",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          axios
            .post("/cases/status/log/delete", {
              status_id: status.id,
            })
            .then(function (response) {
              console.log(response);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Deleted!",
                showConfirmButton: false,
                timer: 1500,
              });

              this_.case_statuses = this_.case_statuses.filter(
                (el) => el.id !== status.id
              );
              console.log(this_.case_statuses);
              //this_.deleteStatusItemModal = false;
            })
            .then(function () {
              this_.fetch_case_details();
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Oops...", "Something went wrong!", "error");
            });
        }
      });
    },

    editActionLogItem(action) {
      this.editActionLogItemModal = true;
      this.editActionItemForm = {
        date: action.date_performed,
        user: action.user_initials,
        action: action.action,
        details: action.action_details,
        duration: action.duration,
        action_id: action.id,
      };
      console.log(action);
    },

    billActionLogItem(action) {
      console.log(action);
      var this_ = this;
      axios
        .post("/cases/billing/log/add", {
          user_id: action.user_id,
          user_initials: action.user_initials,
          case_id: action.case_id,
          action_id: action.id,
          action: action.action,
          date: action.date_performed,
          billed_by: this_.user.initials,
          duration: action.duration,
          amnt_billed: 0,
          amnt_paid: 0,
        })
        .then(function (response) {
          console.log(response);
          if (response.data === "ok") {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Billed!",
              showConfirmButton: false,
              timer: 1500,
            });
            this_.fetch_case_details();
          }
          if (response.data === "error") {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Already billed!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    editBillingLogItem(billing) {
      this.editBillingLogItemModal = true;
      console.log("billing:", JSON.stringify(billing));
      var date = billing.billing_date.split(" ");
      date = date[0];

      this.editBillingItemForm = {
        billing_id: billing.id,
        date: date,
        user: billing.user_initials,
        action: billing.action,
        amnt_billed: billing.amount_billed,
        amnt_paid: 0,
      };
      console.log(billing);
    },

    editPaymentItem(billing) {
      this.editPaymentItemModal = true;
      console.log("billing:", JSON.stringify(billing));
      this.editPaymentItemForm = {
        billing_id: billing.id,
        date: billing.billing_date,
        user: billing.billed_by,
        user_initials: billing.user_initials,
        action: billing.action,
        amnt_billed: billing.amount_billed,
        amnt_paid: billing.amount_paid,
        lawyer: billing.user_initials,
      };
      console.log(billing);
    },

    deleteBillingLogItem(billing) {
      var this_ = this;
      Swal.fire({
        title: "Confirm Delete",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          axios
            .post("/cases/billing/log/delete", {
              billing_id: billing.id,
            })
            .then(function (response) {
              console.log(response);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Deleted!",
                showConfirmButton: false,
                timer: 1500,
              });

              this_.case_payments = this_.case_payments.filter(
                (el) => el.id !== billing.id
              );
              console.log(this_.case_payments);
              //this_.deleteStatusItemModal = false;
            })
            .then(function () {
              this_.fetch_case_details();
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Oops...", "Something went wrong!", "error");
            });
        }
      });
    },

    editBillingLogItemFormSubmit(user, case_file) {
      console.log("saving..");
      console.log(this.editBillingItemForm);
      var this_ = this;
      axios
        .post("/cases/billing/log/edit", {
          billing_id: this_.editBillingItemForm.billing_id,
          user: this_.editBillingItemForm.user,
          date: this_.editBillingItemForm.date,
          action: this_.editBillingItemForm.action,
          amnt_billed: this_.editBillingItemForm.amnt_billed,
          amnt_paid: this_.editBillingItemForm.amnt_paid,
        })
        .then(function (response) {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Saved!",
            showConfirmButton: false,
            timer: 1500,
          });
          let objIndex = this_.case_payments.findIndex(
            (b) => b.id === this_.editBillingItemForm.billing_id
          );
          this_.case_payments[objIndex] = {
            billing_date: this_.editBillingItemForm.date,
            user_initials: this_.editBillingItemForm.user,
            action: this_.editBillingItemForm.action,
            amount_billed: parseInt(this_.editBillingItemForm.amnt_billed),
            amount_paid: parseInt(this_.editBillingItemForm.amnt_paid),
            // duration: this_.editBillingItemForm.duration,
          };
          this_.calculate_billings();
          console.log(this_.case_payments);
          this_.editBillingLogItemModal = false;
        })
        .then(function () {
          this_.fetch_case_details();
          this_.editBillingLogItemModal = false;
        })

        .catch(function (error) {
          console.log(error);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    editPaymentItemFormSubmit(user, case_file) {
      console.log("saving..");
      var this_ = this;
      axios
        .post("/cases/payment/log/edit", {
          billing_id: this_.editPaymentItemForm.billing_id,
          user: this_.editPaymentItemForm.user,
          date: this_.editPaymentItemForm.date,
          action: this_.editPaymentItemForm.action,
          amnt_billed: this_.editPaymentItemForm.amnt_billed,
          amnt_paid: this_.editPaymentItemForm.amnt_paid,
          lawyer: this_.editPaymentItemForm.user_initials,
        })
        .then(function (response) {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Saved!",
            showConfirmButton: false,
            timer: 1500,
          });
          let objIndex = this_.case_payments.findIndex(
            (b) => b.id === this_.editBillingItemForm.billing_id
          );
          this_.case_payments[objIndex] = {
            billing_date: this_.editBillingItemForm.date,
            user_initials: this_.editBillingItemForm.user,
            action: this_.editBillingItemForm.action,
            amount_billed: parseInt(this_.editBillingItemForm.amnt_billed),
            amount_paid: parseInt(this_.editBillingItemForm.amnt_paid),
            // duration: this_.editBillingItemForm.duration,
          };
          this_.calculate_billings();
          console.log(this_.case_payments);
          this_.editPaymentItemModal = false;
        })
        .then(function () {
          this_.fetch_case_details();
          this_.editPaymentItemModal = false;
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    editActionLogItemFormSubmit() {
      console.log("saving..");
      var this_ = this;
      axios
        .post("/cases/action/log/edit", {
          action_id: this_.editActionItemForm.action_id,
          user: this_.editActionItemForm.user,
          date: this_.editActionItemForm.date,
          action: this_.editActionItemForm.action,
          details: this_.editActionItemForm.details,
          duration: this_.editActionItemForm.duration,
        })
        .then(function (response) {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Saved!",
            showConfirmButton: false,
            timer: 1500,
          });
          let objIndex = this_.case_actions.findIndex(
            (action) => action.id === this_.editActionItemForm.action_id
          );
          this_.case_actions[objIndex] = {
            date_performed: this_.editActionItemForm.date,
            action: this_.editActionItemForm.action,
            action_details: this_.editActionItemForm.details,
            user_initials: this_.editActionItemForm.user,
            duration: this_.editActionItemForm.duration,
          };
          console.log(this_.case_actions);
          this_.editActionLogItemModal = false;
          // this_.fetch_case_details();
        })
        .then(function () {
          this_.fetch_case_details();
          this_.editActionLogItemModal = false;
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    deleteActionLogItem(action) {
      // this.deleteActionLogItemModal = true;
      var this_ = this;
      Swal.fire({
        title: "Confirm Delete",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          axios
            .post("/cases/action/log/delete", {
              action_id: action.id,
            })
            .then(function (response) {
              console.log(response);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Deleted!",
                showConfirmButton: false,
                timer: 1500,
              });

              this_.case_actions = this_.case_actions.filter(
                (el) => el.id !== action.id
              );
              console.log(this_.case_actions);
              this_.deleteActionLogItemModal = false;
            })
            .then(function () {
              this_.fetch_case_details();
              this_.deleteActionLogItemModal = false;
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Oops...", "Something went wrong!", "error");
            });
        }
      });
      // console.log(action);
    },

    submit_edit_budates() {
      var this_ = this;
      axios
        .post("/cases/update/bu", {
          case_id: this_.case_file.id,
          pri_user: this_.bu_dates_form.pri_user,
          pri_user_bu_date: this_.bu_dates_form.pri_user_bu_date,
          sec_user: this_.bu_dates_form.sec_user,
          sec_user_bu_date: this_.bu_dates_form.sec_user_bu_date,
        })
        .then(function (response) {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Saved!",
            showConfirmButton: false,
            timer: 1500,
          });
          this_.bringupDatesModal = false;
        })
        .then(function () {
          this_.bringupDatesModal = false;
          this_.fetch_case_details();
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    sumbit_edit_case(case_file) {
      var this_ = this;
      axios
        .post("/cases/update", {
          case_id: case_file.id,
          client_name: this_.edit_form.client_name,
          reference: this_.edit_form.reference,
          date: this_.edit_form.date,
          category: this_.edit_form.category,
          summary: this_.edit_form.summary,
        })
        .then(function (response) {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Saved!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .then(function () {
          this_.editModal = false;
          this_.fetch_case_details();
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    fetch_case_details() {
      this.loading = true;

      const id = window.location.pathname.split("/")[3];

      this.case_id = id;
      var this_ = this;

      axios
        .get(`/case/${id}`)
        .then(function (res) {
          // handle success
          // console.log(id);
          console.log(res);
          this_.case_file = res.data.case;
          this_.case_actions = res.data.actions;
          this_.case_payments = res.data.payments;
          this_.case_statuses = res.data.statuses;
          this_.billed_actions = res.data.billed_actions;

          console.log(this_.case_actions);
          console.log(this_.case_payments);
          console.log(this_.case_statuses);

          this_.edit_form.client_name = res.data.case.client_name;
          this_.edit_form.reference = res.data.case.file_reference;
          this_.edit_form.date = res.data.case.date_opened;
          this_.edit_form.summary = res.data.case.matter_summary;
          this_.edit_form.category = res.data.case.category;

          this_.bu_dates_form.pri_user = res.data.case.pri_lawyer;
          this_.bu_dates_form.pri_user_bu_date =
            res.data.case.bring_up_date_pri;
          this_.bu_dates_form.sec_user = res.data.case.sec_lawyer;
          this_.bu_dates_form.sec_user_bu_date =
            res.data.case.bring_up_date_sec;

          this_.loading = false;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          this_.loading = false;
        });
    },

    submit_status_log(user, case_file) {
      var this_ = this;
      axios
        .post("/cases/status/log", {
          user_id: user.id,
          user_initials: this_.status_log_form.user,
          case_id: case_file.id,
          date: this_.status_log_form.date,
          status: this_.status_log_form.status,
        })
        .then(function (response) {
          console.log(response);
          this_.case_statuses = [
            ...this_.case_statuses,
            {
              user_initials: this_.status_log_form.user,
              date_performed: this_.status_log_form.date,
              status: this_.status_log_form.status,
            },
          ];
          this_.status_log_form = {
            date: "",
            user: "",
            status: "",
          };
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Added to Status Log",
            showConfirmButton: false,
            timer: 1500,
          });
          this_.newStatusItemModal = false;
        })
        .then(function () {
          this_.fetch_case_details();
          this_.newStatusItemModal = false;
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Oops...", "Something went wrong", "error");
        });
    },
    submit_action_log(user, case_file) {
      var this_ = this;
      axios
        .post("/cases/action/log", {
          user_id: user.id,
          user_initials: this_.action_log_form.user_initials,
          case_id: case_file.id,
          date: this_.action_log_form.date,
          status: this_.action_log_form.status,
          action: this_.action_log_form.action,
          details: this_.action_log_form.details,
          duration: this_.action_log_form.duration,
          billed_by: this_.user.initials,
        })
        .then(function (response) {
          console.log(response);
          this_.case_actions = [
            ...this_.case_actions,
            {
              user_initials: this_.action_log_form.user_initials,
              date_performed: this_.action_log_form.date,
              action: this_.action_log_form.action,
              action_details: this_.action_log_form.details,
              duration: this_.action_log_form.duration,
              hrs: 0,
              mins: 0,
            },
          ];
          this_.case_payments = [
            ...this_.case_payments,
            {
              user_id: user.id,
              user_initials: this_.action_log_form.user_initials,
              case_id: case_file.id,
              action: this_.action_log_form.action,
              billing_date: this_.action_log_form.date,
              amount_billed: user.rate * this_.action_log_form.duration,
              amount_paid: 0,
            },
          ];

          // this_.calculate_billings();
          this_.action_log_form = {
            date: "",
            user_id: "",
            action: "",
            duration: "",
            details: "",
          };
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Added to Action Log",
            showConfirmButton: false,
            timer: 1500,
          });
          this_.newLogItemModal = false;
        })

        .then(function () {
          this_.newLogItemModal = false;
          this_.fetch_case_details();
          this_.calculate_billings();
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },
    submit_new_payment(user, case_file) {
      console.log(this.billing_form);
      var this_ = this;
      axios
        .post("/cases/payment/log/add", {
          user_id: user.id,
          user_initials: user.initials,
          case_id: case_file.id,
          action_id: null,
          action: "Payment",
          date: this_.payment_form.date,
          amnt_billed: 0,
          amnt_paid: this_.payment_form.amnt_paid,
          billed_by: this_.user.initials,
          lawyer: this_.payment_form.user_initials,
        })
        .then(function (response) {
          console.log(response);
          this_.case_payments = [
            ...this_.case_payments,
            {
              user_id: user.id,
              user_initials: this_.payment_form.user_initials,
              case_id: case_file.id,
              action: this_.payment_form.action,
              billing_date: this_.payment_form.date,
              amount_billed: this_.payment_form.amnt_billed,
              amount_paid: this_.payment_form.amnt_paid,
            },
          ];
          this_.payment_form = {
            user_id: "",
            user_initials: "",
            case_id: "",
            action_id: "",
            date: "",
            amnt_billed: "",
            amnt_paid: "",
          };
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Payment Added",
            showConfirmButton: false,
            timer: 1500,
          });
          this_.newPaymentModal = false;
          this_.calculate_billings();
        })
        .then(function () {
          this_.fetch_case_details();
          this_.newPaymentModal = false;
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    edit_payment(user, case_file) {
      console.log(this.billing_form);
      var this_ = this;
      axios
        .post("/cases/billing/log/edit", {
          user_id: user.id,
          user_initials: user.initials,
          case_id: case_file.id,
          action_id: null,
          action: "Payment",
          date: this_.payment_form.date,
          amnt_billed: 0,
          amnt_paid: this_.payment_form.amnt_paid,
          billed_by: this_.user.initials,
          lawyer: this_.payment_form.user_initials,
        })
        .then(function (response) {
          console.log(response);
          this_.case_payments = [
            ...this_.case_payments,
            {
              user_id: user.id,
              user_initials: this_.payment_form.user_initials,
              case_id: case_file.id,
              action: this_.payment_form.action,
              billing_date: this_.payment_form.date,
              amount_billed: this_.payment_form.amnt_billed,
              amount_paid: this_.payment_form.amnt_paid,
            },
          ];
          this_.payment_form = {
            user_id: "",
            user_initials: "",
            case_id: "",
            action_id: "",
            date: "",
            amnt_billed: "",
            amnt_paid: "",
            lawyer: "",
          };
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Payment Edited",
            showConfirmButton: false,
            timer: 1500,
          });
          this_.editPaymentItemModal = false;
          this_.calculate_billings();
        })
        .then(function () {
          this_.fetch_case_details();
          this_.editPaymentItemModal = false;
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    submit_delete() {
      var this_ = this;
      axios
        .post("/cases/delete", {
          case_id: this_.case_id,
          user_initials: this_.user.user_initials,
        })
        .then(function (response) {
          console.log(response);
          this_.deleteModal = false;
        })
        .then(function () {
          this_.deleteModal = false;
          this_.fetch_case_details();
        })
        .then(this.fetch_case_details())
        .catch(function (err) {
          console.log(err);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },
    submit_restore() {
      var this_ = this;
      axios
        .post("/cases/restore", {
          case_id: this_.case_id,
        })
        .then(function (response) {
          console.log(response);
          this_.restoreModal = false;
        })
        .then(function () {
          this_.restoreModal = false;
          this_.fetch_case_details();
        })
        .then(this.fetch_case_details())
        .catch(function (err) {
          console.log(err);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    move_to_closed() {
      var this_ = this;
      axios
        .post("/cases/move/closed", {
          case_id: this_.case_id,
          user_id: this_.user.id,
          user_initials: this_.user.initials,
        })
        .then(function (response) {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "File moved to closed",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .then(function () {
          this_.fetch_case_details();
        })
        .catch(function (err) {
          console.log(err);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    move_to_active() {
      var this_ = this;
      axios
        .post("/cases/move/active", {
          case_id: this_.case_id,
          user_id: this_.user.id,
          user_initials: this_.user.initials,
        })
        .then(function (response) {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "File moved to Active",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .then(function () {
          this_.fetch_case_details();
        })
        .then(this.fetch_case_details())
        .catch(function (err) {
          console.log(err);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    move_to_sendtoclient() {
      var this_ = this;
      axios
        .post("/cases/move/sendtoclient", {
          case_id: this_.case_id,
          user_id: this_.user.id,
          user_initials: this_.user.initials,
        })
        .then(function (response) {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "File moved to 'Send To Client'",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .then(function () {
          this_.fetch_case_details();
        })
        .catch(function (err) {
          console.log(err);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    move_to_dormant() {
      var this_ = this;
      axios
        .post("/cases/move/dormant", {
          case_id: this_.case_id,
          user_id: this_.user.id,
          user_initials: this_.user.initials,
        })
        .then(function (response) {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "File moved to Dormant",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .then(function () {
          this_.editStatusItemModal = false;
          this_.fetch_case_details();
        })
        .catch(function (err) {
          console.log(err);
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    decimal_to_ordinary(time) {
      var hrs = Math.floor(time);
      var mins = (time - hrs) * 60;

      this.action_log_form.hrs = hrs;
      this.action_log_form.mins = mins;

      return [hrs, mins];
    },

    ordinary_to_decimal(hrs, mins) {
      var h = parseInt(hrs);
      var m = mins / 60;

      const total = h + m;
      console.log(total);

      this.action_log_form.hrs = hrs;
      this.action_log_form.mins = mins;
      this.action_log_form.duration = total.toFixed(2);

      this.editActionItemForm.hrs = hrs;
      this.editActionItemForm.mins = mins;
      this.editActionItemForm.duration = total.toFixed(2);

      return total.toFixed(2);
    },

    fetch_categories() {
      const axios = require("axios");

      var vm = this;

      axios
        .get("/datasets/categories")
        .then(function (res) {
          // handle success
          vm.categories = res.data;
          console.log(vm.categories);
          // vm.loading = false;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          // vm.loading = false;
        });
    },
    fetch_lawyers() {
      const axios = require("axios");

      var vm = this;

      axios
        .get("/datasets/lawyers")
        .then(function (res) {
          // handle success
          vm.lawyers = res.data;
          console.log("lawyers", vm.lawyers);
          // vm.loading = false;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          // vm.loading = false;
        });
    },
    fetch_actions() {
      const axios = require("axios");

      var vm = this;

      axios
        .get("/datasets/actions")
        .then(function (res) {
          // handle success
          vm.actions = res.data;
          console.log(vm.actions);
          // vm.loading = false;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          // vm.loading = false;
        });
    },

    calculate_billings() {
      const id = window.location.pathname.split("/")[3];

      var bills = [];

      let paid = [];
      let billed = [];
      // console.log(bills);
      const axios = require("axios");

      var this_ = this;

      axios
        .get(`/case/${id}`)
        .then(function (res) {
          // handle success
          // console.log(id);
          console.log(res);

          bills = res.data.payments;
          console.log(bills);
          bills.forEach((b) => {
            paid.push(b.amount_paid || 0);
            billed.push(b.amount_billed || 0);
          });

          paid = paid.reduce(function (s, v, idx) {
            return parseInt(s) + parseInt(v);
          });

          billed = billed.reduce(function (s, v, idx) {
            return parseInt(s) + parseInt(v);
          });

          this_.paid_total = paid;
          this_.billed_total = billed;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },

    download_pdf(resource, data, client) {
      this.downloading = true;
      this.download_section = resource;
      this.download_type = "pdf";

      console.log("downloading...");

      let res_ids = [];

      data.forEach((el) => {
        res_ids.push(el.id);
      });

      console.log("ids: ", res_ids);

      var this_ = this;
      axios
        .post("/download/pdf", {
          res: resource,
          ids: JSON.stringify(res_ids),
          client: client,
        })
        .then(function (response) {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Downloaded!",
            showConfirmButton: false,
            timer: 1500,
          });
          this_.downloading = false;
        })
        .catch(function (error) {
          console.log(error);
          this_.downloading = false;

          // Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },

    get_date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },

    download_excel(resource, data, client) {
      this.downloading = true;
      this.download_section = resource;
      this.download_type = "excel";

      console.log("downloading...");

      var filename;

      switch (resource) {
        case "actions":
          filename = "Action Log for " + client;
          break;
        case "billings":
          filename = "Billing Log for " + client;
          break;
        case "status":
          filename = "Status Log for " + client;
          break;
        case "statuses":
          filename = "Status Log for " + client;
          break;

        default:
          break;
      }

      let res_ids = [];

      data.forEach((el) => {
        res_ids.push(el.id);
      });

      console.log("ids: ", res_ids);

      var this_ = this;
      axios
        .post(
          "/download/excel",
          {
            res: resource,
            ids: JSON.stringify(res_ids),
            client: client,
          },
          {
            responseType: "blob",
          }
        )
        .then(function (response) {
          console.log(response);

          FileSaver.saveAs(response.data, filename);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Downloaded!",
            showConfirmButton: false,
            timer: 1500,
          });
          this_.downloading = false;
        })
        .catch(function (error) {
          console.log(error);
          this_.downloading = false;

          // Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },
  },
};
</script>

<style scoped>
.loading-gif {
  margin-left: 25%;
  margin-top: 15%;
}
.spacer {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 40%;
}

.gif {
  height: 20px;
}
</style>
